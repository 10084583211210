import React, { useState, useEffect, useRef, useCallback } from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { X, Plus, Calendar, MapPin, Clock, Trash2, CheckCircle, ChevronLeft, ChevronRight, Edit3, WalletMinimal } from 'lucide-react';
import { RiYoutubeLine, RiInstagramLine, RiFacebookBoxFill } from "react-icons/ri";
import API_UTIL from '../../services/AuthIntereptor';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import AppBar from '../../components/AppBar/AppBar';
import Footer from '../../components/Footer/Footer';
import LoadingSpinner from '../../components/Loader/LoadingSpinner';
import LabelAndInput from '../../components/molecules/LabelAndInput/LabelAndInput';
import defaultBanner from '../../media/images/defaultBanner.png';


const colors = {
  primary: '#1fb8f9',
  secondary: '#0000ff',
  background: '#ffffff',
  text: '#000000',
  lightGray: '#f0f0f0',
  darkGray: '#666666',
};

const sharedButtonStyles = `
  background-color: ${colors.primary};
  color: ${colors.background};
  border: none;
  border-radius: 20px;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: ${colors.secondary};
    color: ${colors.text};
  }
`;

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: ${colors.background};
  color: ${colors.text};
  position: relative;
  overflow-x: hidden;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex: 1;
  position: relative;
`;

const MainContent = styled.div`
  flex: 1;
  padding: 2rem;
  margin-left: ${({ isPanelOpen }) => (isPanelOpen ? '320px' : '0')};
  transition: margin-left 0.3s ease, width 0.3s ease;
  width: ${({ isPanelOpen }) => (isPanelOpen ? 'calc(100% - 320px)' : '100%')};
  

  @media (max-width: 1024px) {
    margin-left: 0;
    width: 100%;
    padding: 1rem;
  }
`;

const TabContent = styled(motion.div)`
  background: ${colors.background};
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 0 15px rgba(31, 184, 249, 0.5);
`;

const TabSwitcher = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
  background-color: ${colors.lightGray};
  border-radius: 20px;
  padding: 0.5rem;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const TabButton = styled.button`
  ${sharedButtonStyles}
  background-color: ${props => props.active ? colors.primary : 'transparent'};
  color: ${props => props.active ? colors.background : colors.text};
  padding: 10px 20px;
  margin: 0 5px;

  &:hover {
    background-color: ${props => props.active ? colors.primary : colors.secondary};
    color: ${colors.background};
  }

  @media (max-width: 768px) {
    width: 100%;
    margin: 5px 0;
  }
`;

const EventGrid = styled(motion.div)`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 2rem;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const EventCard = styled(motion.div)`
  background-color: ${colors.background};
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: relative;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 0 15px rgba(31, 184, 249, 0.3);
  }
`;

const EventImage = styled.img`
  width: 100%;
  height: 200px;
  object-fit: cover;
`;

const EventInfo = styled.div`
  padding: 1rem;
`;

const EventName = styled.h3`
  margin: 0 0 0.5rem;
  font-size: 1.2rem;
  color: ${colors.text};
`;

const EventDetail = styled.p`
  margin: 0.25rem 0;
  font-size: 0.9rem;
  color: ${colors.darkGray};
  display: flex;
  align-items: center;

  svg {
    margin-right: 0.5rem;
    color: ${colors.primary};
  }
`;

const CreateEventCard = styled(motion.div)`
  background-color: ${colors.lightGray};
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  // padding: 2rem;
  height: 100%;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 0 15px rgba(31, 184, 249, 0.3);
  }
`;

const PlusIcon = styled(Plus)`
  width: 3rem;
  height: 3rem;
  color: ${colors.primary};
  margin-bottom: 1rem;
`;

const CreateEventText = styled.p`
  font-size: 1rem;
  color: ${colors.text};
  text-align: center;
`;

const DeleteButton = styled(Trash2)`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  color: ${colors.background};
  background: ${colors.darkGray};
  padding: 0.25rem;
  border-radius: 50%;
  cursor: pointer;

  &:hover {
    background: ${colors.primary};
    color: ${colors.text};
  }
`;

const PanelContainer = styled.div`
  position: fixed;
  top: 105px;
  left: ${({ isOpen }) => (isOpen ? '0' : '-320px')};
  width: 320px;
  // height: calc(100vh - 60px);
  background-color: ${colors.background};
  transition: left 0.3s ease;
  z-index: 1000;
  overflow-y: auto;
  box-shadow: 0 0 15px rgba(31, 184, 249, 0.5);

  @media (max-width: 1024px) {
    left: ${({ isOpen }) => (isOpen ? '0' : '-100%')};
    width: 100%;
  }
`;

const ToggleButton = styled.button`
  position: fixed;
  top: 95px;
  left: ${({ isOpen }) => (isOpen ? '320px' : '0')};
  background-color: ${colors.primary};
  border: none;
  border-radius: 0 5px 5px 0;
  padding: 10px;
  cursor: pointer;
  color: ${colors.background};
  transition: all 0.3s ease;
  z-index: 1001;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: ${colors.secondary};
  }

  @media (max-width: 1024px) {
    top: 60px;
    left: ${({ isOpen }) => (isOpen ? 'calc(100% - 40px)' : '0')};
    padding: 8px;
  }

  svg {
    width: 20px;
    height: 20px;

    @media (max-width: 768px) {
      width: 16px;
      height: 16px;
    }
  }
`;


const BannerImageContainer = styled.div`
  position: relative;
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors.lightGray};
`;

const BannerImage = styled.div`
  width: 100%;
  height: 100%;
  background-image: url(${({ src }) => src});
  background-size: cover;
  background-position: center;
`;

const EditBannerButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: ${colors.primary};
  border: none;
  border-radius: 5px;
  padding: 5px;
  cursor: pointer;
  color: ${colors.background};
  transition: all 0.3s ease;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: ${colors.secondary};
  }

  @media (max-width: 768px) {
    padding: 3px;
    top: 5px;
    right: 5px;
  }

  svg {
    width: 16px;
    height: 16px;

    @media (max-width: 768px) {
      width: 14px;
      height: 14px;
    }
  }
`;

const ProfileInfo = styled.div`
  padding: 20px;
  color: ${colors.text};
`;

const BrandName = styled.h2`
  margin-bottom: 10px;
  color: ${colors.text};
  font-size: 1.5rem;
`;

const SocialLinks = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
`;

const SocialLink = styled.a`
  color: ${colors.darkGray};
  text-decoration: none;
  transition: all 0.3s ease;

  &:hover {
    color: ${colors.primary};
    transform: scale(1.1);
  }
`;

const ActionButtons = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  margin-top: 20px;
  padding: 0 20px 20px;
`;

const ActionButton = styled.button`
  ${sharedButtonStyles}
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  svg {
    margin-bottom: 5px;
  }
`;

// Modal styles
const ModalContent = styled.div`
  background-color: ${colors.background};
  border-radius: 10px;
  padding: 2rem;
  outline: none;
  color: ${colors.text};
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 500px;
  width: 90%;
  margin: 0 auto;

  @media (max-width: 768px) {
    padding: 1rem;
    width: 95%;
  }
`;


const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`;

const ModalTitle = styled.h2`
  font-size: 1.5rem;
  color: ${colors.primary};
  margin: 0;
`;

const CloseButton = styled.button`
  background: ${colors.background};
  border: 2rem;
  font-size: 1.2rem;
  cursor: pointer;
  color: ${colors.text};

    &:hover {
    box-shadow: 0 0 15px rgba(0, 255, 255, 0.5);
    transform: scale(1.03);
  }
`;

const Form = styled.form`
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;

  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  color: ${colors.text};
  margin-bottom: 0.5rem;
  font-weight: 500;
`;

const Input = styled.input`
  background-color: ${colors.lightGray};
  border: 1px solid ${colors.darkGray};
  color: ${colors.text};
  padding: 0.5rem;
  border-radius: 4px;
`;

const Select = styled.select`
  background-color: ${colors.lightGray};
  border: 1px solid ${colors.darkGray};
  color: ${colors.text};
  padding: 0.5rem;
  border-radius: 4px;
`;

const TextArea = styled.textarea`
  background-color: ${colors.lightGray};
  border: 1px solid ${colors.darkGray};
  color: ${colors.text};
  padding: 0.5rem;
  border-radius: 4px;
  resize: vertical;
  min-height: 100px;
`;

const SubmitButton = styled.button`
  ${sharedButtonStyles}
  grid-column: 1 / -1;
`;


// Main component
const Event = () => {
  const [events, setEvents] = useState([]);
  const [attendedEvents, setAttendedEvents] = useState([]);
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isUserDetailsModalOpen, setIsUserDetailsModalOpen] = useState(false);
  const [showNewProjectInput, setShowNewProjectInput] = useState(false);
  const [userDetails, setUserDetails] = useState([]);
  const [displayNone, setDisplayNone] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState('attendedFlashbacks');
  const [eventToDelete, setEventToDelete] = useState(null);
  const [isClaimPopupOpen, setIsClaimPopupOpen] = useState(false);
  const userPhoneNumber = localStorage.userPhoneNumber;
  const [isPanelOpen, setIsPanelOpen] = useState(true);
  const [deleteMessage, setDeleteMessage] = useState("");
  const [showSuccessAnimation, setShowSuccessAnimation] = useState(false);
  const [isLoadingAttendedEvents, setIsLoadingAttendedEvents] = useState(false);
  const [attendedEventsLoading, setAttendedEventsLoading] = useState(false);


  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    eventName: '',
    eventDate: '',
    eventTime: '',
    eventLocation: '',
    projectName: '',
    invitationNote: '',
    eventImage: null
  });

  const [selectedFile, setSelectedFile] = useState(null);
  const [userFormData, setUserFormData] = useState({
    user_name: '',
    social_media: {
      instagram: '',
      youtube: ''
    },
    org_name: '',
    role: 'creator'
  });
  const [newProjectName, setNewProjectName] = useState('');
  const [uploading, setUploading] = useState(false);

  useEffect(() => {
    const loadDefaultImage = async () => {
      try {
        const response = await fetch('assets/Images/event-card.jpg');
        const blob = await response.blob();
        const defaultImageFile = new File([blob], 'event-card.jpg', { type: 'image/jpeg' });
        setSelectedFile(defaultImageFile);
      } catch (error) {
        console.error('Error loading default image:', error);
      }
    };

    loadDefaultImage();
  }, []);

  useEffect(() => {
    const fetchProjects = async (clientName) => {
      try {
        const response = await API_UTIL.get(`/getProjectDetails/${clientName}`);
        if (response.status === 200) {
          setProjects(response.data);
        } else {
          setProjects([]);
        }
      } catch (error) {
        console.error('Error fetching projects:', error);
        setProjects([]);
      }
    };

    const fetchUserDetails = async () => {
      try {
        setLoading(true);
        const response = await API_UTIL.get(`/fetchUserDetails/${userPhoneNumber}`);
        setUserDetails(response.data.data);
        fetchEventData(response.data.data.user_name);
        fetchProjects(response.data.data.user_name);
        setTimeout(() => {
          setLoading(false);
        }, 250);
      } catch (error) {
        console.error('Error fetching user details:', error);
        setTimeout(() => {
          setLoading(false);
        }, 250);
      }
    };

    fetchUserDetails();
  }, [userPhoneNumber]);

  const fetchAttendedEvents = async () => {
    setIsLoadingAttendedEvents(true);
    try {
      const attendedResponse = await API_UTIL.get(`/getUserAttendedEvents/${userPhoneNumber}`);
      setAttendedEvents(attendedResponse.data);
    } catch (error) {
      console.error('Error fetching attended events:', error);
      toast.error('Failed to load attended events. Please try again.');
      setAttendedEvents([]);
    } finally {
      setIsLoadingAttendedEvents(false);
    }
  };

  useEffect(() => {
    if (selectedTab === 'attendedFlashbacks') {
      fetchAttendedEvents();
    }
  }, [selectedTab]);

  const fetchEventData = async (userName) => {
    try {
      const response = await API_UTIL.get(`/getClientEventDetails/${userName}`);
      const collabResponse = await API_UTIL.get(`/getCollabEvents/${userName}`);
      const allEvents = [...response.data, ...collabResponse.data];
      const uniqueEvents = allEvents.reduce((acc, current) => {
        const x = acc.find(item => item.event_id === current.event_id);
        if (!x) {
          return acc.concat([current]);
        } else {
          return acc;
        }
      }, []);

      setEvents(uniqueEvents);

      const attendedResponse = await API_UTIL.get(`/getUserAttendedEvents/${userPhoneNumber}`);
      setAttendedEvents(attendedResponse.data);
    } catch (error) {
      setError(error.message);
      throw Error("Error in fetching Events info");
    }
  };

  const onEventClick = (event_id) => {
    navigate(`/eventDetails/${event_id}`, { state: { userDetails } });
  };

  const onAttendEventClick = (event_id) => {
    navigate(`/photosV1/${event_id}/${userDetails.user_id}`, { state: { userDetails } });
  };
  
  const openDeleteModal = (event) => {
    setEventToDelete(event);
    setIsDeleteModalOpen(true);
  };

  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setEventToDelete(null);
  };

  const openCreateEventModal = () => {
    if (userDetails.user_name === userPhoneNumber) {
      setIsUserDetailsModalOpen(true);
    } else {
      setIsCreateModalOpen(true); 
    }
  };

  const deleteEvent = async (eventId) => {
    try {
      setDeleteMessage("Deleting event...");
      await API_UTIL.delete(`/deleteEvent/${eventId}/${userPhoneNumber}`);
      setEvents(events.filter(event => event.event_id !== eventId));
      setDeleteMessage("Delete successful");
      setShowSuccessAnimation(true);
      setTimeout(() => {
        setIsDeleteModalOpen(false);
        setDeleteMessage("");
        setShowSuccessAnimation(false);
      }, 2000);
    } catch (error) {
      console.error("Error deleting event:", error);
      setDeleteMessage("Failed to delete the event. Please try again.");
      toast.error('Failed to delete the event. Please try again.');
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const closeClaimPopup = () => {
    setIsClaimPopupOpen(false);
  };

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleCreateEvent = async (e) => {
    e.preventDefault();

    const combinedDateTime = `${formData.eventDate}T${formData.eventTime}:00`;

    setUploading(true);

    const formDataToSend = new FormData();
    formDataToSend.append('eventName', formData.eventName);
    formDataToSend.append('eventDate', combinedDateTime);
    formDataToSend.append('eventLocation', formData.eventLocation);
    formDataToSend.append('projectName', formData.projectName);
    formDataToSend.append('clientName', userDetails.user_name);
    formDataToSend.append('invitationNote', formData.invitationNote);
    if (selectedFile) {
      formDataToSend.append('eventImage', selectedFile);
    }

    try {
      const response = await API_UTIL.post('/saveEventDetails', formDataToSend, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.status !== 200) {
        throw new Error('Failed to save the event');
      }
      setEvents([...events, response.data.data]);
      setIsCreateModalOpen(false);
      return response;
    } catch (error) {
      console.error('Error saving form data to backend:', error);
      toast.error('Failed to save the events. Please try again.');
    } finally {
      setUploading(false);
    }
  };

  const handleNewProjectChange = (e) => {
    setNewProjectName(e.target.value);
  };

  const handleCreateProject = async () => {
    if (!newProjectName) {
      toast.error('Please enter a project name.');
      return;
    }

    try {
      const response = await API_UTIL.post('/saveProjectDetails', { projectName: newProjectName, clientName: userDetails.user_name });
      setProjects([response.data.data, ...projects]);
      setFormData({ ...formData, projectName: response.data.data.project });
      setNewProjectName('');
      setShowNewProjectInput(false);
      toast.success('Project created successfully');
    } catch (error) {
      console.error('Error creating project:', error);
      toast.error('Failed to create project. Please try again.');
    } finally {
      setDisplayNone(!displayNone);
    }
  };

  const handleUserDetailsSubmit = async (e) => {
    e.preventDefault();
    try {
      const requestData = {
        user_phone_number: userPhoneNumber,
        ...userFormData
      };

      const res = await API_UTIL.post("/updatePortfolioDetails", requestData);
      if (res.status === 200) {
        setUserDetails(res.data.data);
        setIsUserDetailsModalOpen(false);
        setIsCreateModalOpen(true); 
      }
    } catch (error) {
      console.error("Error in registering the model:", error);
      if (error.response) {
        toast.error("Error in creating Model");
      }
    }
  };

  if (loading) return <LoadingSpinner />;
  if (error) return <div>Error: {error}</div>;

  const togglePanel = () => {
    setIsPanelOpen(!isPanelOpen);
  };

    return (
      <PageWrapper>
        <AppBar/>
        <ContentWrapper>
      <ProfilePanel
          userDetails={userDetails}
          isOpen={isPanelOpen}
          togglePanel={togglePanel}
        />
        <MainContent isPanelOpen={isPanelOpen}>
          <TabContent
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.3 }}
          >
            <TabSwitcher>
              <TabButton
                active={selectedTab === 'myFlashbacks'}
                onClick={() => setSelectedTab('myFlashbacks')}
              >
                My Events
              </TabButton>
              <TabButton
                active={selectedTab === 'attendedFlashbacks'}
                onClick={() => setSelectedTab('attendedFlashbacks')}
              >
                Tagged Events
              </TabButton>
            </TabSwitcher>
  
            <AnimatePresence mode="wait">
              {selectedTab === 'myFlashbacks' && (
                <EventGrid
                  key="myFlashbacks"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.3 }}
                >
                  <CreateEventCard onClick={openCreateEventModal}>
                    <PlusIcon />
                    <CreateEventText>Create New Event</CreateEventText>
                  </CreateEventCard>
                  {events.map((event) => (
                    <EventCard
                    key={event.event_id}
                    onClick={() => onEventClick(event.event_id)}
                    whileHover={{ scale: 1.03 }}
                    whileTap={{ scale: 0.98 }}
                  >
                    <EventImage src={event.event_image} alt={event.event_name} />
                    <EventInfo>
                      <EventName>{event.event_name}</EventName>
                      <EventDetail>
                        <Calendar size={16} />
                        {new Date(event.event_date).toLocaleDateString()}
                      </EventDetail>
                      <EventDetail>
                        <Clock size={16} />
                        {new Date(event.event_date).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                      </EventDetail>
                      <EventDetail>
                        <MapPin size={16} />
                        {event.event_location}
                      </EventDetail>
                    </EventInfo>
                    <DeleteButton onClick={(e) => {
                      e.stopPropagation();
                      openDeleteModal(event);
                    }} />
                  </EventCard>
                ))}
              </EventGrid>
            )}

            {selectedTab === 'attendedFlashbacks' && (
              <EventGrid
                key="attendedFlashbacks"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.3 }}
              >
                {attendedEvents === null ? (
                  <LoadingSpinner/>
                ) : (
                attendedEvents.map((event) => (
                  <EventCard
                    key={event.event_id}
                    onClick={() => onAttendEventClick(event.folder_name)}
                    whileHover={{ scale: 1.03 }}
                    whileTap={{ scale: 0.98 }}
                  >
                    <EventImage src={event.event_image} alt={event.event_name} />
                    <EventInfo>
                      <EventName>{event.event_name}</EventName>
                    </EventInfo>
                  </EventCard>
                ))
                )}
              </EventGrid>
            )}
          </AnimatePresence>
        </TabContent>
      </MainContent>
      </ContentWrapper>

      {/* Modals */}
      <Modal
        isOpen={isUserDetailsModalOpen}
        onRequestClose={() => setIsUserDetailsModalOpen(false)}
        contentLabel="User Details"
        className="modal-content"
        overlayClassName="modal-overlay"
      >
        <ModalContent>
          <ModalHeader>
            <ModalTitle>User Details</ModalTitle>
            <CloseButton onClick={() => setIsUserDetailsModalOpen(false)}>
              <X size={18} />
            </CloseButton>
          </ModalHeader>
          <Form onSubmit={handleUserDetailsSubmit}>
            <FormGroup>
              <LabelAndInput
                label="User Name:"
                type="text"
                id="userName"
                name="userName"
                value={userFormData.org_name}
                handleChange={(e) => setUserFormData({ ...userFormData, org_name: e.target.value })}
                placeholder="Enter your photography name"
                isRequired={true}
                isEditable={true}
              />
            </FormGroup>
            <FormGroup>
              <LabelAndInput
                label="Instagram URL:"
                type="text"
                id="instagram"
                name="instagram"
                value={userFormData.social_media.instagram}
                handleChange={(e) => setUserFormData({
                  ...userFormData,
                  social_media: {
                    ...userFormData.social_media,
                    instagram: e.target.value,
                  },
                })}
                isEditable={true}
              />
            </FormGroup>
            <FormGroup>
              <LabelAndInput
                label="YouTube URL:"
                type="text"
                id="youtube"
                name="youtube"
                value={userFormData.social_media.youtube}
                handleChange={(e) => setUserFormData({
                  ...userFormData,
                  social_media: {
                    ...userFormData.social_media,
                    youtube: e.target.value,
                  },
                })}
                isEditable={true}
              />
            </FormGroup>
            <SubmitButton type="submit">Save</SubmitButton>
          </Form>
        </ModalContent>
      </Modal>

      <Modal
        isOpen={isDeleteModalOpen}
        onRequestClose={closeDeleteModal}
        contentLabel="Delete Confirmation"
        className="modal-content"
        overlayClassName="modal-overlay"
      >
        <ModalContent>
          <ModalHeader>
            <ModalTitle>Confirm Delete</ModalTitle>
            <CloseButton onClick={closeDeleteModal}>
              <X size={18} />
            </CloseButton>
          </ModalHeader>
          {deleteMessage ? (
            <>
              <p>{deleteMessage}</p>
              {showSuccessAnimation && (
                <motion.div
                  initial={{ scale: 0 }}
                  animate={{ scale: 1 }}
                  transition={{ duration: 0.5 }}
                >
                  <CheckCircle size={48} color={colors.primary} />
                </motion.div>
              )}
            </>
          ) : (
            <>
              <p>Are you sure you want to delete this event?</p>
              <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '1rem' }}>
                <SubmitButton onClick={() => deleteEvent(eventToDelete.event_id)}>Delete</SubmitButton>
                <SubmitButton onClick={closeDeleteModal} style={{ backgroundColor: colors.lightGray, color: colors.text }}>Cancel</SubmitButton>
              </div>
            </>
          )}
        </ModalContent>
      </Modal>

      <Modal
        isOpen={isCreateModalOpen}
        onRequestClose={() => setIsCreateModalOpen(false)}
        contentLabel="Create Event"
        className="modal-content"
        overlayClassName="modal-overlay"
      >
        <ModalContent>
          <ModalHeader>
            <ModalTitle>Create Event</ModalTitle>
            <CloseButton onClick={() => setIsCreateModalOpen(false)}>
              <X size={18} />
            </CloseButton>
          </ModalHeader>
          <Form onSubmit={handleCreateEvent}>
            <FormGroup>
              <Label htmlFor="eventName">Event Name</Label>
              <Input
                type="text"
                id="eventName"
                name="eventName"
                value={formData.eventName}
                onChange={handleInputChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="eventDate">Event Date</Label>
              <Input
                type="date"
                id="eventDate"
                name="eventDate"
                value={formData.eventDate}
                onChange={handleInputChange}
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="eventTime">Event Time</Label>
              <Input
                type="time"
                id="eventTime"
                name="eventTime"
                value={formData.eventTime}
                onChange={handleInputChange}
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="eventLocation">Event Location</Label>
              <Input
                type="text"
                id="eventLocation"
                name="eventLocation"
                value={formData.eventLocation}
                onChange={handleInputChange}
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="projectName">Project Name</Label>
              <Select
                id="projectName"
                name="projectName"
                value={formData.projectName}
                onChange={handleInputChange}
                required
              >
                <option value="">Select Project</option>
                {projects.map((project, index) => (
                  <option key={index} value={project}>
                    {project}
                  </option>
                ))}
              </Select>
            </FormGroup>
            <FormGroup>
              <Label>Create New Project</Label>
              <div>
                <Input
                  type="text"
                  placeholder="New Project Name"
                  value={newProjectName}
                  onChange={handleNewProjectChange}
                />
                <ActionButton type="button" onClick={handleCreateProject}>
                  Add Project
                </ActionButton>
              </div>
            </FormGroup>
            <FormGroup style={{ gridColumn: '1 / -1' }}>
              <Label htmlFor="invitationNote">Invitation Note</Label>
              <TextArea
                id="invitationNote"
                name="invitationNote"
                value={formData.invitationNote}
                onChange={handleInputChange}
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="eventImage">Upload Image</Label>
              <Input
                type="file"
                id="eventImage"
                name="eventImage"
                accept="image/*"
                onChange={handleFileChange}
              />
            </FormGroup>
            <SubmitButton type="submit" disabled={uploading}>
              {uploading ? "Creating..." : "Create Event"}
            </SubmitButton>
          </Form>
        </ModalContent>
      </Modal>

      <Footer />
    </PageWrapper>
  );
};

const ProfilePanel = ({ userDetails, isOpen, togglePanel }) => {
  const navigate = useNavigate();
  const [bannerImage, setBannerImage] = useState(defaultBanner);
  const [isLoading, setIsLoading] = useState(true);
  const [timestamp, setTimestamp] = useState(Date.now());
  const fileInputRef = useRef(null);
  const [isWalletModalOpen, setIsWalletModalOpen] = useState(false);

  useEffect(() => {
    if (userDetails.org_name && userDetails.user_name) {
      fetchBannerImage();
    }
  }, [userDetails]);

  const encodeURIWithPlus = (uri) => {
    return uri.replace(/ /g, '+');
  };

  const fetchBannerImage = useCallback(async () => {
    setIsLoading(true);
    if (userDetails.org_name && userDetails.user_name) {
      try {
        const response = await API_UTIL.get(`/getBannerImage/${userDetails.user_name}`);
        if (response.data && response.data.imageUrl) {
          const formattedUrl = encodeURIWithPlus(response.data.imageUrl);
          setBannerImage(`${formattedUrl}?t=${Date.now()}`);
        } else {
          setBannerImage(defaultBanner);
        }
      } catch (error) {
        console.error('Error fetching banner image:', error);
        setBannerImage(defaultBanner);
      } finally {
        setIsLoading(false);
      }
    } else {
      setBannerImage(defaultBanner);
      setIsLoading(false);
    }
  }, [userDetails.org_name, userDetails.user_name, timestamp]);

  const handleBannerEdit = () => {
    fileInputRef.current.click();
  };

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      setIsLoading(true);
      const formData = new FormData();
      formData.append('bannerImage', file);
      formData.append('userName', userDetails.user_name);

      try {
        const response = await API_UTIL.post('/updateBannerImage', formData, {
          headers: { 'Content-Type': 'multipart/form-data' }
        });
        if (response.data && response.data.imageUrl) {
          const formattedUrl = encodeURIWithPlus(response.data.imageUrl);
          setBannerImage(`${formattedUrl}?t=${Date.now()}`);
        }
      } catch (error) {
        console.error('Error uploading banner image:', error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <>
      <ToggleButton onClick={togglePanel} isOpen={isOpen}>
        {isOpen ? <ChevronLeft /> : <ChevronRight />}
      </ToggleButton>
      <PanelContainer isOpen={isOpen}>
        <BannerImageContainer>
          <BannerImage src={bannerImage} />
          <EditBannerButton onClick={handleBannerEdit}>
            <Edit3 size={16} />
          </EditBannerButton>
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: 'none' }}
            onChange={handleImageUpload}
            accept="image/*"
          />
        </BannerImageContainer>
        <ProfileInfo>
          <BrandName>{userDetails.org_name || userDetails.user_phone_number}</BrandName>
          <SocialLinks>
            {userDetails.social_media?.instagram && (
              <SocialLink href={userDetails.social_media.instagram} target="_blank" rel="noopener noreferrer">
                <RiInstagramLine size={24} />
              </SocialLink>
            )}
            {userDetails.social_media?.youtube && (
              <SocialLink href={userDetails.social_media.youtube} target="_blank" rel="noopener noreferrer">
                <RiYoutubeLine size={24} />
              </SocialLink>
            )}
            {userDetails.social_media?.facebook && (
              <SocialLink href={userDetails.social_media.facebook} target="_blank" rel="noopener noreferrer">
                <RiFacebookBoxFill size={24} />
              </SocialLink>
            )}
          </SocialLinks>
        </ProfileInfo>
        {/* <ActionButtons>
          <ActionButton onClick={() => setIsWalletModalOpen(true)}>
            <WalletMinimal size={20} />
            Wallet
          </ActionButton>
          <ActionButton onClick={() => navigate('/portfolioForm')}>
            <Edit3 size={20} />
            Portfolio
          </ActionButton>
        </ActionButtons> */}
      </PanelContainer>
    </>
  );
};


export default Event;