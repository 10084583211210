import React from 'react'
import './AppBar.css'
import { COMPANY_NAME } from '../../helpers/constants';
import { useNavigate } from 'react-router-dom';

const AppBar = ({ showLogout = true }) => {
  const navigate = useNavigate();

  const handleLogout = () => {
    // Remove session storage
   localStorage.clear();

    // Navigate to the default page '/'
    navigate('/');
  };
  return (
    <div className='app-bar'>
      <div className='app-bar-logo'>
          <img
              src="https://www.imageshield.com/hs-fs/hubfs/Imageshield%20New%20Website%20Feb%202023/images/imageshield_full_logo_color.png?height=56&name=imageshield_full_logo_color.png"
              alt="ImageShield Logo"
              style={{marginBottom: '1rem'}}
          />
      </div>
        <div className='app-bar-socials'>
        <a href="https://x.com/image_shield" target="_blank" rel="noopener noreferrer">
         <img src="assets/Images/icon-footer-x.svg" alt="Twitter" />*
          </a>
           <a href="https://www.instagram.com/image_shield/" target="_blank" rel="noopener noreferrer">
           <img src="assets/Images/icon-footer-instagram.svg" alt="Instagram" />
         </a>
      </div>
      {showLogout && (
      <div className='logout-section'>
        
          <button className='logout-button' onClick={handleLogout}>
            Logout
          </button>
       
      </div>
       )}
    </div>
  )
}

export default AppBar;
