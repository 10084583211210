import React, { useRef, useState, useEffect } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import Webcam from "react-webcam";
import API_UTIL from "../../../services/AuthIntereptor";
import CountryCodes from "../../../media/json/CountryCodes.json";
import Select, { components } from "react-select";
import AppBar from "../../../components/AppBar/AppBar";
import { Container, Paper, Typography, TextField, Button, Box, Snackbar, Alert, Checkbox, FormControlLabel, useTheme } from '@mui/material';
import "./login.css";
import { motion, AnimatePresence } from 'framer-motion';

const CustomOption = ({ children, ...props }) => (
    <components.Option {...props}>
      <div className="flagOption customOption" style={{ display: "flex" }}>
        <img
            className="iconImg"
            alt={props.data.code}
            src={`assets/CountryFlags/${props.data.code}.png`}
        />
        {props.data.code}
        {children}
      </div>
    </components.Option>
);

const CustomControl = ({ children, ...props }) => (
    <components.Control {...props}>
      <div className="flagOption customControl" style={{ display: "flex" }}>
        <img
            alt={props.getValue()[0]?.code}
            className="iconImg"
            src={`assets/CountryFlags/${props.getValue()[0]?.code}.png`}
        />
        {children}
      </div>
    </components.Control>
);

function Login({ name, onLoginSuccess, showAppBar = false }) {
  const [error] = useState("");
  const { eventName } = useParams();
  const [isNewUser, setIsNewUser] = useState(false);
  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [countryCode, setCountryCode] = useState("+91");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const webcamRef = useRef(null);
  const [imgSrc, setImgSrc] = useState(null);
  const [termsAccepted, setTermsAccepted] = useState(true);
  const isToastDisp = useRef(false);
  const [isCaptureEnabled, setIsCaptureEnabled] = useState(true);

  const theme = useTheme();

  const videoConstraints = {
    width: 350,
    height: 250,
    facingMode: "user",
  };

  let location = useLocation();
  const navigate = useNavigate();

  // Check if user is already logged in
  useEffect(() => {
    const userPhoneNumber = localStorage.getItem("userPhoneNumber");
    if (userPhoneNumber && !location.state?.from?.pathname?.includes('/photos')) {
      navigate(location.state?.from?.pathname || "/event");
    }
  }, [navigate, location.state]);

  useEffect(() => {
    if (isToastDisp.current) return;
    isToastDisp.current = true;
  }, []);

  const fromUrl = location.state?.from?.pathname || "/event";

  const handlePhoneNumberChange = (e) => {
    const value = e.target.value;
    setPhoneNumber(value);

    const phoneRegex = /^\d{10}$/;

    if (!phoneRegex.test(value)) {
      setPhoneNumberError("Please enter a valid 10-digit phone number");
    } else {
      setPhoneNumberError("");
    }
  };

  const capture = async () => {
    if (isCaptureEnabled) {
      const imageSrc = webcamRef.current.getScreenshot();
      setImgSrc(imageSrc);
    }
  };

  const retake = () => {
    setImgSrc(null);
    setIsCaptureEnabled(true);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const fullPhoneNumber = countryCode + phoneNumber;
    await createOrLoginUser(fullPhoneNumber);
  };

  const createOrLoginUser = async (fullPhoneNumber) => {
    let userSource = "imageshield";
    let role = "user";
    let reward_points = 50;
    if (typeof fromUrl === 'string' && fromUrl.includes("photos")) {
      userSource = "imageshield-pro";
    }
    if (typeof fromUrl === 'string' && fromUrl.includes("dataset")) {
      role = "dataOwner";
      reward_points = 100000
    }
    if (typeof fromUrl === 'string' && fromUrl.includes("model")) {
      role = "modelOwner"
      reward_points = 100000
    }

    try {
      const response = await API_UTIL.post(`/createUser`, {
        username: fullPhoneNumber,
        eventName: eventName,
        userSource: userSource,
        role: role,
        reward_points: reward_points
      });
      setIsPhoneNumberValid(true);
      if (response.status === 201) {
        setIsNewUser(true);
      } else if (response.status === 200) {
        localStorage.setItem('userPhoneNumber', fullPhoneNumber);
        handleNavigation(fullPhoneNumber);
      }
    } catch (error) {
      console.error("Error creating/logging in user:", error);
    }
  };

  const handleNavigation = async (fullPhoneNumber) => {
    if (typeof fromUrl === 'string' && fromUrl.includes("photos")) {
      try {
        const urlArray = fromUrl.split("/");
        const response = await API_UTIL.post(`/userIdPhoneNumberMapping`, {
          phoneNumber: fullPhoneNumber,
          eventName: urlArray[urlArray.length - 2],
          userId: urlArray[urlArray.length - 1],
        });
        if (response.status === 200) {
          localStorage.setItem('userPhoneNumber', fullPhoneNumber);
          navigate(fromUrl);
        }
      } catch (error) {
        console.log("Error in mapping the userId and phone number");
      }
    } else {
      navigate(fromUrl);
    }
    localStorage.setItem('userPhoneNumber', fullPhoneNumber);
  };

  const uploadPhoto = async () => {
    if (termsAccepted) {
      const fullPhoneNumber = countryCode + phoneNumber;
      const formData = new FormData();
      formData.append("image", imgSrc);
      formData.append("username", fullPhoneNumber);

      try {
        await API_UTIL.post(`/uploadUserPotrait`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        localStorage.setItem('userPhoneNumber', fullPhoneNumber);
        handleNavigation(fullPhoneNumber);
      } catch (error) {
        console.error("Error uploading image:", error);
      }
    }
  };

  const onChangeCountryCode = (event) => {
    setCountryCode(event.label);
  };
  return (
      <Container component="main" maxWidth="xs">
      <div className="login-page-appbar">
        {showAppBar && (
          <div>
            <AppBar showLogout={false}></AppBar>
          </div>
        )}

           </div>
        <Paper
            component={motion.div}
            initial={{opacity: 0, y: -20}}
            animate={{opacity: 1, y: 0}}
            transition={{duration: 0.5}}
            elevation={3}
            sx={{
              marginTop: 8,
              padding: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              backgroundColor: theme.palette.background.paper,
              color: theme.palette.text.primary
            }}
        >
          <img
              src="https://www.imageshield.com/hs-fs/hubfs/Imageshield%20New%20Website%20Feb%202023/images/imageshield_full_logo_color.png?height=56&name=imageshield_full_logo_color.png"
              alt="ImageShield Logo"
              style={{marginBottom: '1rem'}}
          />

          <Box sx={{width: '100%', marginTop: 2}}>
            {!isPhoneNumberValid ? (
                <form onSubmit={handleSubmit}>
                  <Box sx={{display: 'flex', gap: 2, marginBottom: 2}}>
                    <Select
                        className="country-code"
                        classNamePrefix={"fb"}
                        options={CountryCodes}
                        onChange={onChangeCountryCode}
                        defaultValue={{
                          name: "United States",
                          dial_code: "+1",
                          code: "US",
                          label: "+1",
                          value: "+1",
                        }}
                        components={{Option: CustomOption, Control: CustomControl}}
                    />
                    <TextField
                        fullWidth
                        name="phoneNumber"
                        type="tel"
                        placeholder="Phone Number"
                        onChange={handlePhoneNumberChange}
                        error={!!phoneNumberError}
                        helperText={phoneNumberError}
                    />
                  </Box>
                  <Typography variant="body2" gutterBottom>
                    {/*By submitting your phone number, you confirm you've read this{' '}*/}
                    {/*<a href="/TermsAndConditions" target="_blank" rel="noopener noreferrer">*/}
                    {/*    Privacy Notice*/}
                    {/*</a>*/}
                  </Typography>
                  <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      sx={{mt: 3, mb: 2}}
                      disabled={phoneNumber.length !== 10}
                  >
                    Login
                  </Button>
                </form>
            ) : isNewUser ? (
                <Box>
                  <Typography variant="body2" gutterBottom>
                    Hey, don't worry we won't see your image :)
                  </Typography>
                  {imgSrc ? (
                      <img src={imgSrc} alt="captured" style={{width: '100%'}}/>
                  ) : (
                      <Webcam
                          audio={false}
                          ref={webcamRef}
                          screenshotFormat="image/jpeg"
                          videoConstraints={videoConstraints}
                          mirrored={true}
                          style={{width: '100%'}}
                      />
                  )}
                  {imgSrc ? (
                      <Box>
                        <Button onClick={uploadPhoto} fullWidth variant="contained" sx={{mt: 2}}>
                          Submit photo
                        </Button>
                        <Button onClick={retake} fullWidth variant="outlined" sx={{mt: 1}}>
                          Retake photo
                        </Button>
                        <FormControlLabel
                            control={
                              <Checkbox
                                  checked={termsAccepted}
                                  onChange={(e) => setTermsAccepted(e.target.checked)}
                              />
                            }
                            label={
                              <Typography variant="body2">
                                Accept{' '}
                                <a href="/TermsAndConditions" target="_blank" rel="noopener noreferrer">
                                  terms and conditions
                                </a>
                              </Typography>
                            }
                        />
                      </Box>
                  ) : (
                      isCaptureEnabled && (
                          <Button onClick={capture} fullWidth variant="contained" sx={{mt: 2}}>
                            Capture photo
                          </Button>
                      )
                  )}
                </Box>
            ) : null}
          </Box>
        </Paper>
      </Container>
  );
  // return (
  //     <div className="login-root imageshield-theme">
  //       <div className="login-page-appbar">
  //         {showAppBar && (
  //             <div>
  //               <AppBar showLogout={false}></AppBar>
  //             </div>
  //         )}
  //         <img
  //             src="https://www.imageshield.com/hs-fs/hubfs/Imageshield%20New%20Website%20Feb%202023/images/imageshield_full_logo_color.png?height=56&name=imageshield_full_logo_color.png"
  //             alt="ImageShield Logo"
  //             style={{marginBottom: '1rem'}}
  //         />
  //       </div>
  //       <div className="loginBody">
  //         <div className="loginLeft">
  //           <div className="login-form-container">
  //             {!isPhoneNumberValid && (
  //                 <form onSubmit={handleSubmit} className="login-form">
  //                   <div className={"phoneOuter " + (phoneNumberError && "error")} style={{ position: "relative" }}>
  //                     <Select
  //                         className="country-code"
  //                         classNamePrefix={"is"}
  //                         options={CountryCodes}
  //                         onChange={onChangeCountryCode}
  //                         defaultValue={{
  //                           name: "United States",
  //                           dial_code: "+1",
  //                           code: "US",
  //                           label: "+1",
  //                           value: "+1",
  //                         }}
  //                         components={{ Option: CustomOption, Control: CustomControl }}
  //                     />
  //                     <input name="phoneNumber" required type="tel" placeholder="Phone Number" onChange={handlePhoneNumberChange} />
  //                   </div>
  //                   {/*<div className="privacyNotice">*/}
  //                   {/*  <p>By submitting your phone number, you confirm you've read this <a href="/TermsAndConditions" target="_blank">Privacy Notice</a></p>*/}
  //                   {/*</div>*/}
  //                   {phoneNumberError && (
  //                       <p className="error-message">
  //                         *{phoneNumberError}
  //                       </p>
  //                   )}
  //                   <button type="submit" className="submit" disabled={phoneNumber.length !== 10}>Login</button>
  //                   {error && (
  //                       <p className="error-message">
  //                         {error}
  //                       </p>
  //                   )}
  //                 </form>
  //             )}
  //             {isNewUser && (
  //                 <div className="login-form-container">
  //                   <p className="caution">Please capture your photo for account verification</p>
  //                   {imgSrc ? (
  //                       <img src={imgSrc} alt="captured" />
  //                   ) : (
  //                       <Webcam audio={false} ref={webcamRef} screenshotFormat="image/jpeg" style={{ zIndex: 0 }} videoConstraints={videoConstraints} mirrored={true} />
  //                   )}
  //                   <div className="btn-container">
  //                     {imgSrc ? (
  //                         <div className="login-form-container">
  //                           <form className="login-form">
  //                             <button type="button" onClick={uploadPhoto} className="submit submitPhoto">Submit photo</button>
  //                             <button type="button" className="button takePhoto" onClick={retake}>Retake photo</button>
  //                             <label style={{ display: "flex", justifyContent: "center" }}>
  //                               <input name="checkbox" type="checkbox" defaultChecked required style={{ transform: "scale(1.1)" }} onChange={(e) => setTermsAccepted(e.target.checked)} onInvalid={(e) => e.target.setCustomValidity("Please accept the terms and conditions.")} onInput={(e) => e.target.setCustomValidity("")} />
  //                               <a href="/TermsAndConditions" target="_blank" rel="noopener noreferrer" className="termsAndConditionButton">Accept terms and conditions</a>
  //                             </label>
  //                           </form>
  //                         </div>
  //                     ) : (
  //                         <div className="login-form-container">
  //                           {isCaptureEnabled && (
  //                               <form className="login-form">
  //                                 <button className="button takePhoto" type="button" onClick={capture}>Capture photo</button>
  //                               </form>
  //                           )}
  //                         </div>
  //                     )}
  //                   </div>
  //                 </div>
  //             )}
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  // );
}

export default Login;